export const checkArrow = (
  <svg
    width="10"
    height="8"
    viewBox="0 0 10 8"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.66775 0.255056C8.84055 0.089653 9.07051 -0.00178848 9.30909 2.65108e-05C9.54768 0.0018415 9.77623 0.0967713 9.94653 0.264784C10.1168 0.432797 10.2155 0.660752 10.2218 0.900549C10.2282 1.14034 10.1416 1.37323 9.98033 1.55005L5.08574 7.70436C5.00157 7.79551 4.89999 7.86865 4.78707 7.91942C4.67415 7.97018 4.5522 7.99754 4.42853 7.99984C4.30485 8.00214 4.18198 7.97935 4.06726 7.93282C3.95255 7.88629 3.84835 7.81698 3.76088 7.72903L0.514995 4.46564C0.424602 4.38096 0.352101 4.27884 0.301815 4.16537C0.25153 4.05191 0.224491 3.92942 0.222311 3.80522C0.220132 3.68102 0.242856 3.55765 0.289129 3.44248C0.335402 3.3273 0.404276 3.22267 0.491641 3.13483C0.579006 3.047 0.683073 2.97775 0.797633 2.93123C0.912194 2.88471 1.0349 2.86186 1.15844 2.86405C1.28197 2.86624 1.4038 2.89343 1.51666 2.94398C1.62951 2.99454 1.73108 3.06743 1.81531 3.15831L4.38406 5.73967L8.64444 0.28219L8.66775 0.255056Z" />
  </svg>
);

export const hamburger = (
  <svg
    width="18"
    height="12"
    viewBox="0 0 22 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 15.001H21M1 8.00098H21M1 1.00098H21"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const cart = (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.98837 12.0457H14.5842C14.8711 12.0457 15.1216 11.7853 15.1216 11.4353C15.1216 11.0853 14.8711 10.8249 14.5842 10.8249H6.11345C5.69359 10.8249 5.43589 10.4992 5.36958 10.003L5.25173 9.14852H14.5987C15.6741 9.14852 16.2266 8.41588 16.3812 7.25235L16.9705 2.94715C16.9861 2.84492 16.996 2.74171 17 2.63812C17 2.2475 16.7348 1.97875 16.3297 1.97875H4.3017L4.16153 0.945418C4.08799 0.318684 3.88184 0.000976562 3.13074 0.000976562H0.54494C0.250471 0.000976562 0 0.286392 0 0.612086C0 0.945418 0.250471 1.23049 0.545255 1.23049H3.03457L4.21307 10.1659C4.36801 11.3214 4.92018 12.0457 5.98837 12.0457ZM15.7476 3.20756L15.2253 7.10582C15.1662 7.61033 14.9233 7.9197 14.4887 7.9197L5.09019 7.92769L4.46386 3.20756H15.7476ZM6.5704 16.0009C6.72746 16.0024 6.88321 15.9692 7.02857 15.9035C7.17394 15.8377 7.306 15.7407 7.41706 15.618C7.52813 15.4953 7.61597 15.3494 7.67548 15.1888C7.73499 15.0282 7.76497 14.8561 7.76367 14.6825C7.76434 14.5092 7.73392 14.3374 7.67419 14.1771C7.61446 14.0168 7.52659 13.8712 7.41565 13.7486C7.30471 13.6261 7.1729 13.529 7.02783 13.463C6.88275 13.397 6.72729 13.3634 6.5704 13.3641C5.90038 13.3641 5.36989 13.9502 5.36989 14.6825C5.36989 15.4232 5.90038 16.0009 6.5704 16.0009ZM13.4573 16.0009C14.1276 16.0009 14.6578 15.4232 14.6578 14.6825C14.6578 13.9499 14.1276 13.3641 13.4573 13.3641C12.7945 13.3641 12.2568 13.9502 12.2568 14.6825C12.2568 15.4232 12.7945 16.0009 13.4573 16.0009Z"
      fill="white"
    />
  </svg>
);

export const search = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6464 15.3545C14.8417 15.5498 15.1583 15.5498 15.3536 15.3545C15.5488 15.1593 15.5488 14.8427 15.3536 14.6474L10.9865 10.2803C11.3809 9.81311 11.7016 9.28704 11.9364 8.72026C12.2351 7.99905 12.3889 7.22606 12.3889 6.44542C12.3889 5.66479 12.2351 4.89179 11.9364 4.17058C11.6377 3.44937 11.1998 2.79406 10.6478 2.24206C10.0958 1.69007 9.4405 1.25221 8.71929 0.95347C7.99807 0.654734 7.22508 0.500977 6.44444 0.500977C5.66381 0.500977 4.89082 0.654734 4.1696 0.95347C3.44839 1.25221 2.79308 1.69007 2.24109 2.24206C1.68909 2.79406 1.25123 3.44937 0.952494 4.17058C0.653758 4.89179 0.5 5.66479 0.5 6.44542C0.5 7.22606 0.653758 7.99905 0.952494 8.72026C1.25123 9.44147 1.68909 10.0968 2.24109 10.6488C2.79308 11.2008 3.44839 11.6386 4.1696 11.9374C4.89082 12.2361 5.66381 12.3899 6.44444 12.3899C7.22508 12.3899 7.99807 12.2361 8.71929 11.9374C9.28606 11.7026 9.81214 11.3819 10.2794 10.9874L14.6464 15.3545Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const close = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1L17 17M1 17L17 1"
      stroke="white"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const arrow = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <path d="M1.169,16c-.081-.019-.161-.037-.242-.056-.581-.138-.963-.649-.925-1.238.036-.553.511-1.043,1.094-1.067.449-.018.903.018,1.353.052,1.498.114,2.995.239,4.492.358,1.518.121,3.037.238,4.555.359.686.055,1.372.114,2.059.169.038.003.077-.009.156-.019-.066-.073-.106-.125-.153-.168-1.796-1.667-3.591-3.334-5.389-4.998-.442-.409-.902-.799-1.342-1.211C4.732,6.218,2.64,4.252.548,2.285.175,1.933-.087,1.532.031.981.21.141,1.172-.25,1.871.267c.337.249.62.574.909.882,1.708,1.813,3.409,3.632,5.116,5.446.408.433.836.848,1.242,1.282,1.752,1.873,3.5,3.751,5.247,5.628.046.049.076.113.114.17.026-.014.053-.028.079-.043,0-.07.005-.14,0-.209-.089-1.166-.179-2.332-.27-3.498-.105-1.352-.21-2.705-.315-4.057-.105-1.352-.219-2.704-.312-4.057-.022-.327-.023-.67.05-.987C13.855.289,14.347-.025,14.909.002c.497.024.946.415,1.04.927.036.196.049.399.049.599.003,4.558.002,9.115.002,13.673,0,.508-.077.619-.547.801H1.169Z" />
  </svg>
);

export const plus = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M10.736 22.538c-.026.31-.05.635.077.918.145.324.49.547.843.544s.695-.231.835-.557c.098-.229.1-.486.099-.736l-.001-10.625c0-.189-.008-.4-.141-.534-.103-.104-.253-.133-.403-.14a2.885 2.885 0 0 0-.13-.003l-10.654.004c-.243 0-.494.002-.718.098-.317.138-.539.473-.543.819s.21.687.524.831c.276.127.595.106.898.083l10.153-.695" />
    <path d="M12.472 11.414c-.027.029.044-.649.047-.688.251-3.102.461-6.163.713-9.266.025-.31.048-.635-.08-.918-.145-.324-.491-.546-.844-.542s-.695.233-.833.56c-.097.229-.098.487-.097.736l.034 10.625c0 .189.01.4.143.534.104.104.253.132.404.139.044.002.088.002.13.002l10.654-.038c.243 0 .494-.003.718-.101.317-.138.537-.473.54-.82s-.212-.686-.526-.829c-.277-.126-.595-.104-.898-.08" />
  </svg>
);

export const minus = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 1.996">
    <path d="M23.108 1.148c1.321-.072.855-.799.6-.938-.442-.241-1.165-.2-1.442-.208a9.804 9.804 0 0 0-.241-.003L2.331.005c-.45 0-.914.002-1.327.106C.418.259.008.619 0 .992s.388.739.968.895c.511.137 1.099.114 1.66.089l18.767-.748" />
  </svg>
);

export const trash = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
  >
    <path
      d="M6.77097 2.73586C6.94112 2.24284 7.25664 1.81592 7.67406 1.51395C8.09147 1.21197 8.59022 1.0498 9.10156 1.0498C9.6129 1.0498 10.1117 1.21197 10.5291 1.51395C10.9465 1.81592 11.262 2.24284 11.4322 2.73586M16.1016 4.41985H2.10156M14.7287 6.52485L14.3499 12.3346C14.2042 14.5693 14.1317 15.6866 13.4193 16.3678C12.707 17.0498 11.6109 17.0498 9.42027 17.0498H8.78286C6.59227 17.0498 5.49615 17.0498 4.7838 16.3678C4.07144 15.6866 3.99815 14.5693 3.85321 12.3346L3.47439 6.52485M7.04274 8.62984L7.4545 12.8398M11.1604 8.62984L10.7486 12.8398"
      stroke="#2A1314"
      strokeLinecap="round"
    />
  </svg>
);

export const quote = (
  <svg
    width="61"
    height="48"
    viewBox="0 0 61 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M24.8592 48H0.5L4.08963 24.9873C6.65364 8.54758 16.2687 0 32.2948 0L30.6282 10.5201C21.7823 10.5201 17.6799 15.7802 16.5261 23.0137H28.7052L24.8592 48ZM32.2948 24.9863C34.8588 8.54759 44.4739 0 60.5 0L58.8334 10.5201C49.9875 10.5201 45.8851 15.7802 44.7313 23.0137H56.9114L53.0653 48H28.7062L32.2968 24.9873L32.2948 24.9863Z" />
  </svg>
);

export const navArrow = (
  <svg
    width="23"
    height="24"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 125.255 123.955"
  >
    <path d="M66.153,122.201c-.404.246-.808.493-1.213.737-2.928,1.762-6.542,1.192-8.758-1.379-2.08-2.414-2.111-6.326.18-8.759,1.764-1.873,3.759-3.54,5.734-5.2,6.577-5.527,13.194-11.006,19.789-16.512,6.686-5.582,13.361-11.179,20.048-16.761,3.023-2.524,6.063-5.027,9.09-7.546.167-.139.278-.344.561-.704-.563-.036-.932-.089-1.3-.08-14.037.348-28.075.69-42.112,1.06-3.451.091-6.898.333-10.349.403-16.454.335-32.908.643-49.363.939-2.94.053-5.624-.548-7.359-3.278-2.646-4.164-.29-9.621,4.644-10.298,2.377-.327,4.842-.123,7.261-.021,14.267.605,28.533,1.262,42.8,1.875,3.408.146,6.824.134,10.232.29,14.691.676,29.38,1.386,44.07,2.094.386.019.767.159,1.15.243.05-.164.101-.327.151-.491-.281-.287-.544-.593-.845-.857-5.054-4.43-10.112-8.855-15.171-13.279-5.867-5.131-11.734-10.261-17.603-15.389-5.869-5.128-11.771-10.22-17.591-15.404-1.408-1.254-2.79-2.658-3.764-4.249-1.646-2.689-.897-5.951,1.503-8.089,2.126-1.894,5.531-2.084,7.975-.361.936.66,1.803,1.441,2.607,2.261,18.34,18.694,36.667,37.401,54.995,56.106,2.042,2.084,2.176,2.847.986,5.475-19.449,19.058-38.897,38.117-58.346,57.175Z" />
  </svg>
);

export const add = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
  >
    <path
      d="M8 15.5C4.14 15.5 1 12.36 1 8.5C1 4.64 4.14 1.5 8 1.5C11.86 1.5 15 4.64 15 8.5C15 12.36 11.86 15.5 8 15.5ZM8 2.5C4.69 2.5 2 5.19 2 8.5C2 11.81 4.69 14.5 8 14.5C11.31 14.5 14 11.81 14 8.5C14 5.19 11.31 2.5 8 2.5Z"
      fill="#2A1314"
    />
    <path
      d="M8 12C7.72 12 7.5 11.78 7.5 11.5V5.5C7.5 5.22 7.72 5 8 5C8.28 5 8.5 5.22 8.5 5.5V11.5C8.5 11.78 8.28 12 8 12Z"
      fill="#2A1314"
    />
    <path
      d="M11 9H5C4.72 9 4.5 8.78 4.5 8.5C4.5 8.22 4.72 8 5 8H11C11.28 8 11.5 8.22 11.5 8.5C11.5 8.78 11.28 9 11 9Z"
      fill="#2A1314"
    />
  </svg>
);

export const angleUp = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
  >
    <g clipPath="url(#clip0_43_4717)">
      <path
        d="M1 17.5L12 7.5L23 17.5"
        stroke="#2A1314"
        strokeWidth="2"
        strokeLinecap="square"
      />
    </g>
    <defs>
      <clipPath id="clip0_43_4717">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="matrix(-1 0 0 -1 24 24.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const error = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
  >
    <path
      d="M7.9987 11.8333C8.18759 11.8333 8.34603 11.7693 8.47403 11.6413C8.60203 11.5133 8.66581 11.355 8.66537 11.1666C8.66492 10.9781 8.60092 10.8199 8.47337 10.6919C8.34581 10.5639 8.18759 10.4999 7.9987 10.4999C7.80981 10.4999 7.65159 10.5639 7.52403 10.6919C7.39648 10.8199 7.33248 10.9781 7.33203 11.1666C7.33159 11.355 7.39559 11.5135 7.52403 11.6419C7.65248 11.7704 7.8107 11.8341 7.9987 11.8333ZM7.9987 9.16659C8.18759 9.16659 8.34603 9.10259 8.47403 8.97459C8.60203 8.84659 8.66581 8.68836 8.66537 8.49992V5.83325C8.66537 5.64436 8.60137 5.48614 8.47337 5.35859C8.34537 5.23103 8.18714 5.16703 7.9987 5.16659C7.81025 5.16614 7.65203 5.23014 7.52403 5.35859C7.39603 5.48703 7.33203 5.64525 7.33203 5.83325V8.49992C7.33203 8.68881 7.39603 8.84725 7.52403 8.97525C7.65203 9.10325 7.81025 9.16703 7.9987 9.16659ZM7.9987 15.1666C7.07648 15.1666 6.20981 14.9915 5.3987 14.6413C4.58759 14.291 3.88203 13.8161 3.28203 13.2166C2.68203 12.617 2.20714 11.9115 1.85737 11.0999C1.50759 10.2884 1.33248 9.4217 1.33203 8.49992C1.33159 7.57814 1.5067 6.71147 1.85737 5.89992C2.20803 5.08836 2.68292 4.38281 3.28203 3.78325C3.88114 3.1837 4.5867 2.70881 5.3987 2.35859C6.2107 2.00836 7.07737 1.83325 7.9987 1.83325C8.92003 1.83325 9.7867 2.00836 10.5987 2.35859C11.4107 2.70881 12.1163 3.1837 12.7154 3.78325C13.3145 4.38281 13.7896 5.08836 14.1407 5.89992C14.4918 6.71147 14.6667 7.57814 14.6654 8.49992C14.664 9.4217 14.4889 10.2884 14.14 11.0999C13.7911 11.9115 13.3163 12.617 12.7154 13.2166C12.1145 13.8161 11.4089 14.2913 10.5987 14.6419C9.78848 14.9926 8.92181 15.1675 7.9987 15.1666Z"
      fill="#D6170C"
    />
  </svg>
);

export const errorWhite = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24px"
    viewBox="0 -960 960 960"
    width="24px"
    fill="#e8eaed"
  >
    <path d="M480-280q17 0 28.5-11.5T520-320q0-17-11.5-28.5T480-360q-17 0-28.5 11.5T440-320q0 17 11.5 28.5T480-280Zm-40-160h80v-240h-80v240Zm40 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
  </svg>
);
